import React from 'react'

import Spinner from 'reactstrap/lib/Spinner'

export const Loading = ({ className = '' }): JSX.Element => (
  <Spinner
    role="progressbar"
    color="primary"
    className={className}
    style={{ width: '3rem', height: '3rem' }}
  />
)
