import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import {
  faFileWord,
  faFilePdf,
  faMp3Player,
  faFileVideo,
  faFileExcel,
  faFileCsv,
  faFile,
} from '@fortawesome/pro-regular-svg-icons'
import { FileType } from '../enum/file-type.enum'

export class FileImageService {
  static getFileImageByFileType(fileType: string): IconDefinition {
    switch (fileType) {
      case FileType.DOC:
      case FileType.DOCX:
        return faFileWord
      case FileType.MP3:
        return faMp3Player
      case FileType.MP4:
        return faFileVideo
      case FileType.PDF:
        return faFilePdf
      case FileType.XLS:
      case FileType.XLSX:
        return faFileExcel
      case FileType.CSV:
        return faFileCsv
      default:
        return faFile
    }
  }

  static getFileImageByFileName(fileName: string): IconDefinition {
    const splitName = fileName.split('.')
    const fileType = splitName[splitName.length - 1]
    return this.getFileImageByFileType(fileType)
  }
}
