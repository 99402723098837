import React, { useContext, useState } from 'react'

interface BackButtonData {
  returnUrl: string
  text: string
}

const BackButtonContext = React.createContext<BackButtonData | null>(null)
const BackButtonUpdateContext = React.createContext<
  (newValue: BackButtonData | null) => void
>(() => null)

export const useBackButton = () => useContext(BackButtonContext)

export const useBackButtonUpdate = () => useContext(BackButtonUpdateContext)

export const BackButtonProvider: React.FC = ({ children }) => {
  const [backButtonData, setBackButtonData] = useState<BackButtonData | null>(
    null
  )

  const setBackButton = (newButtonData: BackButtonData | null) => {
    setBackButtonData(newButtonData)
  }

  return (
    <BackButtonContext.Provider value={backButtonData}>
      <BackButtonUpdateContext.Provider value={setBackButton}>
        {children}
      </BackButtonUpdateContext.Provider>
    </BackButtonContext.Provider>
  )
}
