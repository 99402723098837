import { MissingData, SyllabusCandidate } from '../../../common'

export const applySearchFilter = (
  candidates: SyllabusCandidate[],
  filter: string
): SyllabusCandidate[] => {
  const filt = filter.toLowerCase()
  return candidates.filter(
    (c) =>
      c.name.toLowerCase().includes(filt) ||
      c.candidateNumber.replace(/^0+/, '').toLowerCase().startsWith(filt)
  )
}

export const hasErrors = (missingData: MissingData): boolean =>
  missingData.missingPredicted > 0

export const getErrorCounts = (
  candidates: SyllabusCandidate[]
): MissingData => ({
  missingPredicted: candidates.filter((candidate) => !candidate.grade).length,
})

export const isGraded = (candidate: SyllabusCandidate) => !candidate.grade
