import { faArrowToBottom } from '@fortawesome/pro-solid-svg-icons'
import React, { FC, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Spinner, Col } from 'reactstrap'
import './download-template-button.scss'
import { usePresignedDownload } from '../../../common'

export const DownloadTemplateButton: FC<{
  centreId: string
  id: string
  fileName: string
  businessUnitCssClass: string
}> = ({ centreId, id, fileName, businessUnitCssClass }) => {
  const { pending, download } = usePresignedDownload({
    url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/syllabuses/${id}/candidates/csv`,
    fileName,
  })

  const handleDownload = useCallback(
    (e: React.MouseEvent<any, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation()
      download()
    },
    [download]
  )

  return (
    <Col xs="auto" className={`text-right ${businessUnitCssClass}`}>
      <Button
        color="primary"
        className="text-uppercase download-template-button"
        onClick={(e) => handleDownload(e)}
        data-testid={`syllabus-progress-download-template-button-${id}`}
      >
        {pending ? (
          <Spinner size="sm" className="mr-2" />
        ) : (
          <FontAwesomeIcon
            icon={faArrowToBottom}
            className="mr-2 font-normal"
          />
        )}

        <span className="font-normal">Download</span>
      </Button>
    </Col>
  )
}
