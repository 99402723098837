import React from 'react'
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
} from 'reactstrap'
import './results-card.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'

interface IResultsCard {
  title?: string
  subtitle?: string
  text?: string
  buttonText?: string
  onClick?: () => void
  disabled?: boolean
  dataTestIdSuffix: string | number
}

export const ResultsCard: React.FC<IResultsCard> = ({
  title,
  subtitle,
  text,
  buttonText,
  onClick,
  disabled,
  dataTestIdSuffix,
}) => (
  <Card className="results-card__container">
    <CardBody className="results-card__body d-flex flex-column align-items-stretch">
      <CardTitle
        className="results-card__title"
        tag="h5"
        data-testid={`results-card-title-${dataTestIdSuffix}`}
      >
        {title}
      </CardTitle>
      <CardSubtitle
        className="results-card__subtitle mb-2"
        tag="p"
        data-testid={`results-card-subtitle-${dataTestIdSuffix}`}
      >
        {subtitle}
      </CardSubtitle>
      <CardText
        className="results-card__text"
        data-testid={`results-card-text-${dataTestIdSuffix}`}
      >
        {text}
        <Button
          className="results-card__button"
          data-testid={`results-card-button-${dataTestIdSuffix}`}
          onClick={onClick}
          disabled={disabled}
        >
          {buttonText}
          <FontAwesomeIcon
            icon={faChevronRight}
            className="results-card__button-icon"
          />
        </Button>
      </CardText>
    </CardBody>
  </Card>
)
