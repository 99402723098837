import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'

import {
  isAAM,
  isCAAdmin,
  useAuth0,
  useConfig,
  ResultsCard,
} from '../../common'
import { isFuture, isPast } from 'date-fns'

export const LandingPage: React.FC = (): JSX.Element => {
  const history = useHistory()
  const { user, currentCentre } = useAuth0()
  const { config } = useConfig()

  const isUserCAAdmin = user && isCAAdmin(user)
  const isUserAAM = user && isAAM(user)

  const navigateToAam = () => history.push('/aam')
  const navigateToGradeAssessment = useCallback(
    () =>
      history.push(isUserCAAdmin ? '/centres' : `/centres/${currentCentre}`),
    [history, isUserCAAdmin, currentCentre]
  )

  const isAAMDisabled = useMemo(() => {
    if (isUserCAAdmin) {
      return false
    }
    const { aamAvailableFrom, aamAvailableTo } = config

    return (
      (aamAvailableFrom && isFuture(aamAvailableFrom)) ||
      (aamAvailableTo && isPast(aamAvailableTo))
    )
  }, [config, isUserCAAdmin])

  const isGradeSubmissionDisabled = useMemo(() => {
    if (isUserCAAdmin) {
      return false
    }
    if (isUserAAM) {
      return true
    }

    const { captureAvailableTo, captureAvailableFrom } = config

    return (
      (captureAvailableFrom && isFuture(captureAvailableFrom)) ||
      (captureAvailableTo && isPast(captureAvailableTo))
    )
  }, [isUserCAAdmin, isUserAAM, config])

  return (
    <>
      <Container className="landing-page__container">
        <Row className="justify-content-center mx-auto my-5">
          <Col>
            <h5 className="landing-page__title">Grade Submission System</h5>
          </Col>
        </Row>
        <Row className="justify-content-center mx-auto">
          <Col>
            <ResultsCard
              title="Download Additional Assessment Material"
              subtitle="Download additional assessment material and teacher guidance to help you make your grading judgements."
              text="Available 31 March 2021"
              buttonText="Start"
              onClick={navigateToAam}
              disabled={isAAMDisabled}
              dataTestIdSuffix="landing-page-1"
            />
          </Col>
          <Col>
            <ResultsCard
              title="Submit Grades"
              subtitle="Submit teacher assessed grades for summer 2021."
              text="Available 26 May 2021"
              buttonText="Start"
              onClick={navigateToGradeAssessment}
              disabled={isGradeSubmissionDisabled}
              dataTestIdSuffix="landing-page-2"
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default LandingPage
