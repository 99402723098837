import React, { useMemo } from 'react'
import {
  ProjectModal,
  RsModalBody,
  RsModalFooter,
  RsModalHeader,
  useAuth0,
  User,
} from '../../../common'
import { Button, Col, Row } from 'reactstrap'
import history from '../../../common/services/history'

interface ISelectCenterModal {
  isManuallySelected: boolean
  onClose: () => void
  user: User
}

export const SelectCentreModal: React.FC<ISelectCenterModal> = ({
  isManuallySelected,
  onClose,
  user,
}) => {
  const { setCurrentCentre, currentCentre, isAuthenticated } = useAuth0()

  const isShowModal = useMemo(() => {
    return (
      (((user || {}).centres || []).length > 1 && !currentCentre) ||
      isManuallySelected
    )
  }, [user, currentCentre, isAuthenticated, isManuallySelected])

  return (
    <ProjectModal size="sm" centered isOpen={isShowModal}>
      <RsModalHeader
        toggle={currentCentre ? onClose : undefined}
        className="bg-white px-5 pt-5 pb-0"
      >
        <div className="font-weight-bold text-primary h4">
          {user.name.length > 20
            ? user.name.substring(0, 20) + '...'
            : user.name}
        </div>
        <div className="font-weight-bold text-secondary h5">
          {' '}
          {user.roles.join(' | ')}
        </div>
      </RsModalHeader>
      <RsModalBody className="pt-4 px-5 pb-4">
        <strong>Please select a centre to work on</strong>
        <p>
          You have access to multiple centres, please select one to start
          working
        </p>

        <u className="mb-4">You can switch centres anytime you like</u>
        {user.centres.map((x) => (
          <Row
            key={x}
            className="border rounded mb-3 py-3 px-25 align-items-center"
          >
            <Col>
              <strong>Centre {x}</strong>
            </Col>
            <Col xs="auto" className="text-right">
              <Button
                key={x}
                disabled={x === currentCentre}
                color={x === currentCentre ? 'success' : 'primary'}
                onClick={() => {
                  setCurrentCentre(x)
                  onClose()
                  history.push('/')
                }}
              >
                {x === currentCentre ? 'Currently working on' : 'Select'}
              </Button>
            </Col>
          </Row>
        ))}
      </RsModalBody>
      <RsModalFooter className="font-smaller font-weight-bold text-center px-65 pb-3 text-secondary">
        If you can not find all of your centres in here please contact us
      </RsModalFooter>
    </ProjectModal>
  )
}
