import React from 'react'

import { Row, Col } from 'reactstrap'
import { BackBox } from './subcomponents/back-box'

export interface MessageWithIconProps extends MessagePropsBasic {
  icon?: JSX.Element
}
export interface MessagePropsBasic
  extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  message?: string
}

export const SimpleMessage: React.FC<MessageWithIconProps> = ({
  title,
  message,
  className,
  icon,
  children,
}): JSX.Element => (
  <BackBox className={className}>
    <Row className="my-6">
      <Col className="text-center">
        {icon !== undefined && icon}
        <div className={`${icon ? 'mt-4' : ''} font-weight-bold mb-1 h5`}>
          {title}
        </div>
        {message !== undefined && (
          <p className="mt-4">
            <u>{message}</u>
          </p>
        )}
        {children}
      </Col>
    </Row>
  </BackBox>
)
