import React from 'react'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import getTextFromToken from '../../../common/services/tokenised-text'

export const UploadFinished: React.FC<{ fileName: string }> = ({
  fileName,
}) => {
  return (
    <div className="modal-margin">
      <p className="list-inline-item font-weight-bold">
        Template successfully uploaded
      </p>
      <p className="list-inline-item">{`(${fileName})`}</p>
      <FontAwesomeIcon
        icon={faCheck}
        className="ml-2 text-primary"
        fixedWidth
      />
      <p className="font-smaller">{getTextFromToken('uploadFinished')}</p>
    </div>
  )
}
