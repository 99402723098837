import React from 'react'

import { Row, Col } from 'reactstrap'

export const Title: React.FC<{
  title: string
  subTitle: string
  ancillery?: string
  titleColor?: string
}> = ({ title, subTitle, ancillery, titleColor = 'primary' }) => {
  return (
    <Row className="align-items-center">
      <Col xs="auto">
        <h3 className={`h5 mb-1 font-weight-bold text-${titleColor}`}>
          {title}
        </h3>
        <div className="font-larger font-weight-bold text-secondary">
          {subTitle}{' '}
          {ancillery && (
            <>
              <span className="text-black37 mx-2">|</span> {ancillery}
            </>
          )}
        </div>
      </Col>
    </Row>
  )
}
