import React, { FC, useState, useEffect, useMemo, useCallback } from 'react'
import ReactMarkdown from 'react-markdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLayerGroup, faExchangeAlt } from '@fortawesome/pro-solid-svg-icons'
import querystring from 'querystring'
import {
  isOCR,
  isCambridgeInternational,
  useAuth0,
  Loading,
  InlineErrorMessage,
  useAvailability,
} from '../../common'
import useReactRouter from 'use-react-router'
import four0fourImg from '../../assets/404-serious.svg'
import { Container, Button, Nav, Row, Col } from 'reactstrap'

import ciWelcomeContent from './ci-welcome-content.md'
import ocrWelcomeContent from './ocr-welcome-content.md'

import { SelectCentreModal } from './modal/select-centre-modal'
import { FlowInfo } from '../flow-info/flow-info'

const PrivateRoute: FC<{ roles?: string[] }> = ({
  children,
  roles,
}): JSX.Element => {
  const {
    isAuthenticated,
    loading,
    loginWithRedirect,
    user,
    currentCentre,
  } = useAuth0()

  const [showCentreModal, setShowCentreModal] = useState(false)
  const { location } = useReactRouter()

  const { systemAvailabilityLoaded, userLoaded } = useAvailability()

  useEffect(() => {
    setShowCentreModal(
      ((user || {}).centres || []).length > 1 && !currentCentre
    )
  }, [user, currentCentre, isAuthenticated])

  const isError = useMemo<
    { error: string; errorDescription: string } | undefined
  >(() => {
    const qs = querystring.parse(
      location.search.startsWith('?')
        ? location.search.slice(1)
        : location.search
    )

    if (qs.error) {
      return {
        error: qs.error as string,
        errorDescription: qs.error_description as string,
      }
    }
    return undefined
  }, [location])

  const login = useCallback(() => {
    loginWithRedirect({
      // eslint-disable-next-line @typescript-eslint/camelcase
      redirect_uri: window.location.origin,
      appState: { targetUrl: window.location.pathname },
      prompt: isError ? 'login' : undefined,
    })
  }, [isError, loginWithRedirect])

  const userLoadedWithoutSysAvailabilityConfig =
    !systemAvailabilityLoaded && userLoaded

  if (loading || userLoadedWithoutSysAvailabilityConfig) {
    return (
      <Container>
        <Loading className="mt-5 d-block mx-auto" />
      </Container>
    )
  }

  if (!isAuthenticated) {
    return (
      <>
        <Container className="bg-light p-5 private-route">
          {isCambridgeInternational() && (
            <div className="bg-light rounded text-center markdown-content">
              <ReactMarkdown source={ciWelcomeContent} />
            </div>
          )}
          {isOCR() && [
            <div key="1" className="bg-light rounded markdown-content">
              <ReactMarkdown source={ocrWelcomeContent} />
            </div>,
            <Button
              key="2"
              className="login-button"
              color="primary"
              onClick={() => login()}
            >
              Log in
            </Button>,
          ]}
        </Container>
        <Container className="mt-5 text-center">
          {!isOCR() && (
            <>
              <h5 className="mb-5 text-primary font-weight-bold">
                How it works
              </h5>
              <FlowInfo />
              <Button color="primary" onClick={() => login()}>
                Log in
              </Button>
            </>
          )}

          {isError && (
            <InlineErrorMessage
              className="mt-4"
              title={
                isError.error === 'access_denied'
                  ? 'Access Denied'
                  : 'Something went wrong'
              }
              message={isError.errorDescription || ''}
            />
          )}
        </Container>
      </>
    )
  } else if (
    roles &&
    roles.length > 0 &&
    user &&
    user.roles.filter((x) => roles.includes(x)).length === 0
  ) {
    return (
      <Container>
        <div className={`mt-5 bg-light p-5 mb-5 rounded text-center`}>
          <img src={four0fourImg} alt="not found" />
        </div>
        <div className="text-center">
          Please
          <a href="/">
            <b> go to homepage</b>
          </a>
          , and if the problem persists contact your system administrator
        </div>
      </Container>
    )
  } else {
    return (
      <>
        {user && user.centres.length > 1 && (
          <>
            <SelectCentreModal
              isManuallySelected={showCentreModal}
              onClose={() => setShowCentreModal(false)}
              user={user}
            />
            {currentCentre && (
              <Nav className="error-nav bg-primary text-white font-weight-bold py-3">
                <Container>
                  <Row className="align-items-center">
                    <Col xs="auto">
                      <FontAwesomeIcon icon={faLayerGroup} fixedWidth />
                    </Col>
                    <Col>
                      You have access to multiple centres, currently working on{' '}
                      <u>Centre {currentCentre}</u>
                    </Col>
                    <Col xs="auto">
                      <Button
                        color="white"
                        className="text-primary"
                        onClick={() => setShowCentreModal(true)}
                        data-testid="private-route-switch-centres-button"
                      >
                        <FontAwesomeIcon
                          icon={faExchangeAlt}
                          fixedWidth
                          className="mr-3"
                        />
                        Switch Centres
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Nav>
            )}
          </>
        )}
        {children}
      </>
    )
  }
}

export default PrivateRoute
