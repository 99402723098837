import React, { useMemo } from 'react'
import { Col, Container, Row } from 'reactstrap'

import ciIntroContent from '../../ciIntroContent.md'
import ocrHoCIntroContent from '../../ocrHoCIntroContent.md'
import ocrIntroContent from '../../ocrIntroContent.md'
import ReactMarkdown from 'react-markdown'
import {
  DownloadButton,
  isCambridgeInternational,
  isCentreUser,
  isHOC,
  isOCR,
  useAuth0,
  usePresignedDownload,
} from '../../../../common'

interface ICentreSyllabusPageHeader {
  centreId: string
}

export const CentreSyllabusPageHeader: React.FC<ICentreSyllabusPageHeader> = ({
  centreId,
}): JSX.Element => {
  const { user } = useAuth0()

  const introContent = useMemo(() => {
    if (isCambridgeInternational()) {
      return ciIntroContent
    }

    return user && isHOC(user) ? ocrHoCIntroContent : ocrIntroContent
  }, [user])

  const {
    pending: centreGradeReportPending,
    download: downloadCentreGradeReport,
  } = usePresignedDownload({
    url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/csv`,
    fileName: 'centre-grade-report.csv',
  })

  return (
    <div className="bg-light py-45 px-3 mb-5">
      <Container>
        <Row className="mb-1">
          <Col lg={12}>
            <div
              className="markdown-content"
              data-testid="centre-syllabus-intro-content"
            >
              <ReactMarkdown source={introContent} />
            </div>
          </Col>
          {isOCR() && user && isCentreUser(user) && (
            <Col className="text-right">
              <DownloadButton
                text="Centre grade report"
                pending={centreGradeReportPending}
                download={downloadCentreGradeReport}
                dataTestid="centre-syllabus-grade-report-download-button"
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            {user && isHOC(user) ? (
              <strong>
                If you need to make any changes after you have submitted your
                grades, please contact us.
              </strong>
            ) : (
              <ul className="list-inline font-weight-bold ">
                <li className="list-inline-item mr-5">
                  <span className="mr-2">&bull;</span> Download or upload
                  templates
                </li>
                <li className="list-inline-item mr-5">
                  <span className="mr-2">&bull;</span> Enter or edit grades
                </li>
                <li className="list-inline-item mr-3">
                  <span className="mr-2">&bull;</span> Send for review and
                  approval
                </li>
              </ul>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}
