import { useEffect, useMemo } from 'react'
import { useAsyncTaskAxios, AsyncTask } from 'react-hooks-async'
import axios, { AxiosResponse } from 'axios'

interface UseDownloadProps {
  fileUrl: string
  fileName: string
}

export const useDownloadFile = (
  downloadProps: UseDownloadProps
): AsyncTask<AxiosResponse<any>, unknown[]> => {
  const getFileMemo = useMemo(() => {
    return {
      responseType: 'blob',
      url: downloadProps.fileUrl,
    }
  }, [downloadProps.fileUrl])

  const getFileTask = useAsyncTaskAxios<AxiosResponse<any>>(axios, getFileMemo)

  useEffect(() => {
    if (getFileTask.result) {
      const blob = new Blob([getFileTask.result.data])

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // For IE
        window.navigator.msSaveOrOpenBlob(blob, `${downloadProps.fileName}`)
      } else {
        const downloadUrl = window.URL.createObjectURL(blob)

        const link = document.createElement('a')

        link.href = downloadUrl

        link.setAttribute('download', `${downloadProps.fileName}`) // any other extension

        document.body.append(link)

        link.click()

        link.remove()
        window.URL.revokeObjectURL(downloadUrl)
      }
    }
  }, [getFileTask.result, downloadProps.fileName])

  return getFileTask
}
