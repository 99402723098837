import React from 'react'
import { ROLES, withCentreId, withExpiration } from '../../common'
import { ManagementPage } from './management-page'

const INFO_DESCRIPTION = (
  <>
    Additional Assessment Materials users can download additional assessment
    materials only. They cannot view or enter teacher assessed grades in the
    grade submission system.
    <br />
    Please enter your staff members' details. Users will receive a confirmation
    email with a verification link. Users must click the link to gain access to
    the system. Users will not be able to download additional assessment
    materials until they have done this.
  </>
)

export const ManageAAMPage: React.FC<{ centreId: string }> = ({
  centreId,
}): JSX.Element => (
  <ManagementPage
    centreId={centreId}
    title="Add new Additional Assessment Materials user"
    description={INFO_DESCRIPTION}
    buttonText="Add user"
    emptyListMessage="There are currently no Additional Assessment Materials users at this centre"
    role={ROLES.AAM}
    currentUsersLabel="Current Additional Assessment Materials users"
  />
)

export const ManageAAMPageWithRoute = withCentreId(ManageAAMPage)
export const ManageAAMPageWithExpiration = withExpiration(ManageAAMPage)
export const ManageAAMPageWithRouteAndExpiration = withExpiration(
  ManageAAMPageWithRoute
)
