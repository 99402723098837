import { useMemo } from 'react'
import { useAsyncTaskAxios } from 'react-hooks-async'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { CentreSyllabus } from '../types'

interface Result {
  start: () => void
  result: CentreSyllabus | undefined
  error: string | undefined
  inProgress: boolean
}

export const useApproveSyllabus = (url: string): Result => {
  const requestConfig = useMemo(
    () => ({
      method: 'patch',
      url: url,
      data: {
        valuationStatus: 'submitted',
      },
    }),
    [url]
  )

  const sendForApprovalTask = useAsyncTaskAxios<AxiosResponse>(
    axios,
    requestConfig
  )

  return {
    start: () => sendForApprovalTask.start(),
    result: sendForApprovalTask.result?.data,
    error: (sendForApprovalTask.error as AxiosError)?.response?.data?.message,
    inProgress: sendForApprovalTask.started && sendForApprovalTask.pending,
  }
}
