import { Button, Col, Container, Nav, Row, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowLeft,
  faCheck,
  faCheckCircle,
  faClock,
} from '@fortawesome/pro-solid-svg-icons'
import { faBell } from '@fortawesome/pro-regular-svg-icons'
import getTextFromToken from '../../../../common/services/tokenised-text'
import React, { useState } from 'react'
import { HOCApproveModal } from '../../../hoc-approve-modal/hoc-approve-modal'
import ReactGA from 'react-ga'
import { CentreSyllabus } from '../../../../common'

interface ISubjectPageNotice {
  viewOnlyState: string
  inProgress: boolean
  startTask: () => void
  syllabus: CentreSyllabus | undefined
  syllabusId: string
  approveSyllabus: () => void
}

export const SubjectPageNotice: React.FC<ISubjectPageNotice> = ({
  viewOnlyState,
  inProgress,
  startTask,
  syllabus,
  syllabusId,
  approveSyllabus,
}) => {
  const [showHOCApproval, setShowHOCApproval] = useState(false)

  return (
    <>
      <Nav
        className={`text-white font-weight-bold py-3 ${
          ['approved', 'hoc'].includes(viewOnlyState)
            ? 'bg-success'
            : 'bg-primary'
        }`}
      >
        <Container>
          <Row className="align-items-center">
            {['approved', 'hoc', 'submitted'].includes(viewOnlyState) && (
              <Col>
                <span className="d-inline-flex align-items-center">
                  <FontAwesomeIcon
                    icon={
                      // @ts-ignore
                      {
                        approved: faCheckCircle,
                        hoc: faBell,
                        submitted: faClock,
                      }[viewOnlyState]
                    }
                    fixedWidth
                    className="mr-3"
                  />
                  {
                    // @ts-ignore
                    {
                      approved: `This ${getTextFromToken(
                        'syllabus'
                      )} has been approved and submitted`,
                      hoc: `This ${getTextFromToken(
                        'syllabus'
                      )} is waiting for your approval`,
                      submitted: `This ${getTextFromToken(
                        'syllabus'
                      )} is waiting for approval from the Head of Centre`,
                    }[viewOnlyState]
                  }
                </span>
              </Col>
            )}
            {['hoc'].includes(viewOnlyState) && !inProgress && (
              <>
                <Col xs="auto">
                  <Button
                    color="link"
                    className="px-0 text-white"
                    onClick={startTask}
                    data-testid="syllabus-page-send-for-review-button"
                  >
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                    <span className="underline">
                      Send back for a further review
                    </span>
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button
                    color="white"
                    className=" text-success "
                    onClick={() => setShowHOCApproval(true)}
                    data-testid="syllabus-page-approval-button"
                  >
                    <FontAwesomeIcon
                      icon={faCheck}
                      fixedWidth
                      className="mr-2"
                    />
                    APPROVE
                  </Button>
                </Col>
              </>
            )}
            {viewOnlyState === 'hoc' && inProgress && (
              <Col xs="auto">
                <Spinner size="sm" className="mr-2" />
                Updating
              </Col>
            )}
          </Row>
        </Container>
      </Nav>

      {syllabus && (
        <HOCApproveModal
          syllabus={syllabus}
          isOpen={showHOCApproval}
          close={() => setShowHOCApproval(false)}
          approve={() => {
            setShowHOCApproval(false)
            approveSyllabus()
          }}
        />
      )}
    </>
  )
}
