import React, { useState, useMemo, useEffect } from 'react'
import {
  Button,
  Col,
  Label,
  Row,
  Spinner,
  Form,
  FormGroup,
  Input,
} from 'reactstrap'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { useAsyncTaskAxios } from 'react-hooks-async'

import {
  PreSignedUploadUrl,
  ProjectModal,
  RsModalHeader,
  RsModalBody,
  Title,
} from '../../common'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/pro-solid-svg-icons'
import './syllabus-reopen-button.scss'

enum SyllabusStatusChangeReason {
  Exception = 'Exception',
  QAProcess = 'QA Process',
}

interface ISyllabusReopenButton {
  btnText: string
  header: string
  subheader?: string
  ancillery?: string
  centreId: string
  syllabusId: string
  gradedCandidates: number
  totalCandidates: number
  businessUnitCssClass: string
  syllabusReopened?: (reopened: boolean) => void
}

export const SyllabusReopenButton: React.FC<ISyllabusReopenButton> = ({
  btnText,
  header,
  subheader = '',
  ancillery,
  centreId,
  syllabusId,
  businessUnitCssClass,
  syllabusReopened,
}) => {
  const [statusChangeModalOpen, setStatusChangeModalOpen] = useState<boolean>(
    false
  )
  const [syllabusStatusChangeReason, setSyllabusStatusChangeReason] = useState<
    SyllabusStatusChangeReason
  >()

  const getSyllabusStatusChangeConfig = useMemo<AxiosRequestConfig>(
    () => ({
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/syllabuses/${syllabusId}/reopen`,
      params: {
        changeReason: syllabusStatusChangeReason,
      },
      method: 'PATCH',
    }),
    [centreId, syllabusId, syllabusStatusChangeReason]
  )

  const changeSyllabusStatus = useAsyncTaskAxios<
    AxiosResponse<PreSignedUploadUrl>
  >(axios, getSyllabusStatusChangeConfig)

  const {
    start: startSyllabusStatusChange,
    started: syllabusStatusChangeStarted,
    pending: syllabusStatusChangePending,
    result: syllabusStatusChangeResult,
    error: syllabusStatusChangeError,
  } = changeSyllabusStatus

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    startSyllabusStatusChange()
  }

  useEffect(() => {
    if (
      !!syllabusReopened &&
      syllabusStatusChangeResult?.status === 200 &&
      statusChangeModalOpen === false
    ) {
      syllabusReopened(!!syllabusStatusChangeReason)
    }
  }, [
    syllabusReopened,
    syllabusStatusChangeResult,
    syllabusStatusChangeReason,
    statusChangeModalOpen,
  ])

  return (
    <Col
      xs="auto"
      className={`syllabus-reopen-button text-right ${businessUnitCssClass}`}
    >
      <Button
        color="success"
        className="text-uppercase"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setStatusChangeModalOpen(true)
        }}
        data-testid={`syllabus-progress-reopen-button-${syllabusId}`}
      >
        <FontAwesomeIcon icon={faPen} className="mr-2 font-normal" />
        <span className="font-normal">{btnText}</span>
      </Button>

      <ProjectModal
        isOpen={statusChangeModalOpen}
        toggle={() => setStatusChangeModalOpen(false)}
        centered
        dataTestid="syllabus-reopen-modal"
      >
        <RsModalHeader
          toggle={() => setStatusChangeModalOpen(false)}
          className="bg-white px-5 pt-5 pb-25"
        >
          <Row className="align-items-center justify-content-between mb-2">
            <Title title={header} subTitle={subheader} ancillery={ancillery} />
          </Row>
        </RsModalHeader>
        <RsModalBody className="pt-4 px-5 pb-5 rounded-bottom">
          {!syllabusStatusChangeStarted && !syllabusStatusChangeResult && (
            <>
              <Row className="align-items-center justify-content-between mb-0">
                <h3 className="font-weight-bold h5 mb-1">
                  Change status from Submitted to Complete
                </h3>
              </Row>
              <Row className="align-items-center justify-content-between mb-2">
                <p className="font-larger">
                  Please choose the reason for changing the status:
                </p>
              </Row>
              <Form onSubmit={handleSubmit}>
                <Row className="align-items-center justify-content-between mb-4">
                  <Col className="m-0 mb-2 p-0">
                    {Object.values(SyllabusStatusChangeReason).map((reason) => (
                      <FormGroup check key={reason}>
                        <Label check>
                          <Input
                            className="syllabus-form-status-change-input"
                            checked={syllabusStatusChangeReason === reason}
                            name={reason}
                            type="radio"
                            value={reason}
                            onClick={(e) => e.stopPropagation()}
                            onChange={() => {
                              setSyllabusStatusChangeReason(reason)
                            }}
                            data-testid={`syllabus-form-status-change-input-${reason
                              .toLowerCase()
                              .replace(/\s/, '-')}`}
                          />
                          <p className="font-larger mb-0">{reason}</p>
                        </Label>
                      </FormGroup>
                    ))}
                  </Col>
                </Row>
                <Row>
                  <Button
                    color="primary"
                    disabled={!syllabusStatusChangeReason}
                    type="submit"
                  >
                    Re-open form
                  </Button>
                </Row>
              </Form>
            </>
          )}
          {syllabusStatusChangeStarted &&
            syllabusStatusChangePending &&
            !syllabusStatusChangeResult && (
              <Row className="align-items-center justify-content-center">
                <Spinner size="md" className="m-auto p-auto" />
              </Row>
            )}
          {!syllabusStatusChangeStarted &&
            !syllabusStatusChangePending &&
            syllabusStatusChangeResult && (
              <Row className="align-items-center justify-content-start">
                <Col className="pl-0 text-left">
                  <p className="font-larger text-success">
                    Syllabus form status successfully changed to{' '}
                    <strong>Complete</strong>!
                  </p>
                </Col>
              </Row>
            )}
          {syllabusStatusChangeError && (
            <Row className="align-items-center justify-content-start mt-4">
              <Col className="pl-0 mt-1 text-left">
                <p className="font-larger text-danger">
                  Failed to update syllabus status, please try again and if the
                  problem persists please contact us.
                </p>
              </Col>
            </Row>
          )}
        </RsModalBody>
      </ProjectModal>
    </Col>
  )
}
