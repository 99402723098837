import useReactRouter from 'use-react-router'
import { PathParam } from '../../common'
import React from 'react'
import { ExpiryHoldingPage } from '../../components/holding-page/expiry-holding-page'
const MATCH_PARAM_CENTRE_ID = 'id'

export const withCentreId = (Component: any) => (props: any) => {
  const { match } = useReactRouter<PathParam>()
  return <Component centreId={match.params[MATCH_PARAM_CENTRE_ID]} {...props} />
}

export const withExpiration = (Component: any) => (props: any) => {
  return props.isExpired ? <ExpiryHoldingPage /> : <Component {...props} />
}
