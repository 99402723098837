import React, { useMemo } from 'react'
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Row,
  UncontrolledDropdown,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSortDown } from '@fortawesome/pro-solid-svg-icons'
import { countBy, keys } from 'lodash'

import {
  CentreSyllabus,
  Tab,
  TabContentLabelWithCount,
  TabList,
  ValuationStatus,
} from '../../../../common'

interface ICentreSyllabusPageFilter {
  statusFilter: string
  centres: CentreSyllabus[]
  qualificationFilter: string
  reopenedCount: number
  changeStatusFilter: (key: string) => void
}

const STATUS_FILTERS: { [key: string]: string } = {
  ALL: 'All',
  [ValuationStatus.NOT_STARTED]: 'Not Started',
  [ValuationStatus.INCOMPLETE]: 'In Progress',
  [ValuationStatus.COMPLETE]: 'Ready for Approval',
  [ValuationStatus.SUBMITTED]: 'Awaiting Approval',
  [ValuationStatus.APPROVED]: 'Approved and Submitted',
  [ValuationStatus.OPENED]: 'All Opened',
}

export const CentreSyllabusPageFilter: React.FC<ICentreSyllabusPageFilter> = ({
  statusFilter,
  centres,
  qualificationFilter,
  reopenedCount,
  changeStatusFilter,
}): JSX.Element => {
  const qualCounts = useMemo(() => {
    return countBy(centres, 'qualification')
  }, [centres])

  const uniqueQuals = useMemo(() => {
    return keys(qualCounts).sort()
  }, [qualCounts])

  const filtersCount = useMemo<{ [key: string]: number }>(
    () =>
      centres.reduce(
        (acc, curr) => ({
          ...acc,
          // @ts-ignore
          [curr.valuationStatus]: (acc[curr.valuationStatus] || 0) + 1,
          // @ts-ignore
          ALL: (acc.ALL || 0) + 1,
        }),
        {}
      ),
    [centres]
  )

  return (
    <Row className="mb-5">
      <Col xs="auto" className="text-left font-weight-bold text-secondary">
        <Label className="m-0">Filter by qualification</Label>
        <TabList className="mt-n1">
          <Tab
            className="mt-3"
            autoSize
            to={`?qualification=${encodeURIComponent('All Quals')}`}
            data-testid="centre-syllabus-filter-qual-all"
          >
            <TabContentLabelWithCount
              label="All Quals"
              isActive={qualificationFilter === 'All Quals'}
              count={centres.length}
              countPending={false}
            />
          </Tab>
          {uniqueQuals.map((qual, idx) => (
            <Tab
              className="mt-3"
              autoSize
              key={`qual-${idx}`}
              to={`?qualification=${encodeURIComponent(qual)}`}
              data-testid={`centre-syllabus-filter-qual-${qual}`}
            >
              <TabContentLabelWithCount
                label={qual}
                isActive={qualificationFilter === qual}
                count={qualCounts[qual]}
                countPending={false}
              />
            </Tab>
          ))}
        </TabList>
      </Col>
      <Col
        style={{ minWidth: '280px' }}
        className="d-inline-flex justify-content-end align-items-end pt-4"
      >
        <UncontrolledDropdown>
          <DropdownToggle
            className="ml-3 p-0 label-dropdown d-inline-flex align-content-center"
            data-testid="centre-syllabus-filter-dropdown-toggle"
          >
            Filtered by ({STATUS_FILTERS[statusFilter]})
            <FontAwesomeIcon className="ml-2" icon={faSortDown} />
          </DropdownToggle>

          <DropdownMenu right className="mt-3">
            {Object.keys(STATUS_FILTERS).map((key) => (
              <DropdownItem
                className={`text-left py-3 font-weight-bold mr-4
                        ${key === statusFilter ? 'bg-primary' : ''}`}
                onClick={() => changeStatusFilter(key)}
                key={`filter-dropdown-${key}`}
                data-testid={`centre-syllabus-filter-dropdown-${key}`}
              >
                {key === statusFilter && <FontAwesomeIcon icon={faCheck} />}
                <span className={key === statusFilter ? 'ml-2' : 'ml-4'}>
                  {`${STATUS_FILTERS[key]} (${
                    key === 'opened' ? reopenedCount : filtersCount[key] || 0
                  })`}
                </span>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </Col>
    </Row>
  )
}
