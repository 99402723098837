import React from 'react'
import serverErrorMarkdown from './server-error.md'
import ReactMarkdown from 'react-markdown'

import { faHeartbeat } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ServerErrorHoldingPage = () => (
  <div className="container">
    <div className="holding-page w-100 align-items-center bg-light">
      <FontAwesomeIcon icon={faHeartbeat} size="2x" />
      <ReactMarkdown source={serverErrorMarkdown} />
    </div>
  </div>
)

export default ServerErrorHoldingPage
