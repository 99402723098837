import React from 'react'

import { ModalBody, ModalBodyProps } from 'reactstrap'

export const RsModalBody: React.FC<ModalBodyProps> = ({
  children,
  className,
}) => {
  return (
    <ModalBody
      className={`d-flex flex-column bg-white border-0 text-left ${className} `}
    >
      {children}
    </ModalBody>
  )
}
