import React from 'react'
import {
  isCAAdmin,
  isCambridgeInternational,
  DownloadButton,
  useAuth0,
  usePresignedDownload,
} from '../../../common'

import ReactGA from 'react-ga'

export const OfqualDownloadReport = () => {
  const { user } = useAuth0()

  const {
    pending: ofQualDownloadPending,
    download: downloadOfQualReport,
  } = usePresignedDownload({
    url: `${process.env.REACT_APP_APIDOMAIN}/ofqual-report/url`,
    fileName: 'ofqual.csv',
  })

  if (!(user && isCAAdmin(user))) return <></>

  return (
    <>
      {isCambridgeInternational() && (
        <DownloadButton
          text="Download Ofqual report"
          pending={ofQualDownloadPending}
          download={() => {
            ReactGA.event({
              category: 'CA Support',
              action: 'Download OfQual Report',
            })
            downloadOfQualReport()
          }}
          dataTestid="ca-admin-controls-download-ofqual-report-button"
        />
      )}
    </>
  )
}
