import React from 'react'
import { Container } from 'reactstrap'
import four0fourImg from '../../../assets/404-serious.svg'

export const NotFound = ({ className = '' }): JSX.Element => (
  <Container>
    <div className={`mt-5 bg-light p-5 mb-5 rounded text-center ${className}`}>
      <img src={four0fourImg} alt="not found" />
    </div>
    <div className="text-center">
      Please
      <a href="/">
        <b> go to homepage</b>
      </a>
      , and if the problem persists contact your system administrator
    </div>
  </Container>
)
