import React, { useEffect, useMemo, useState } from 'react'
import axios, { AxiosResponse } from 'axios'
import { ICandidateUpdateExtended } from './constants'

import {
  CentreSyllabus,
  InstructionMaterialDTO,
  SyllabusCandidate,
  isOCR,
  DataSource,
  getSubTitle,
} from '../../common'
import ReactGA from 'react-ga'

import { CandidatesList } from './candidates-list'
import { BulkSetXGradeModal } from '../bulk-set-x-grade-modal/bulk-x-grade-modal'
import { useAsyncTaskAxios } from 'react-hooks-async'
import { ChangingGradeModal } from './modal/changing-grade-modal'
import { IChangeRequest } from './types'

interface ICandidatesListContainer {
  gradeFilter: string
  candidates: SyllabusCandidate[]
  centreId: string
  syllabusId: string
  lastUpdated: number
  showErrors: boolean
  viewOnly: boolean
  toggleShowErrors: (newShow: boolean) => void
  approvalDisabled: (status: boolean) => void
  syllabus: CentreSyllabus
}

export const CandidatesListContainer: React.FC<ICandidatesListContainer> = ({
  gradeFilter,
  showErrors,
  toggleShowErrors,
  candidates,
  centreId,
  syllabusId,
  viewOnly,
  approvalDisabled,
  syllabus,
}): JSX.Element => {
  const [isSetXGradeModalOpen, setIsSetXGradeModalOpen] = useState(false)

  const candidatesBaseUrl = useMemo(
    () =>
      `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/syllabuses/${syllabusId}/candidates`,
    [centreId, syllabusId]
  )
  const [cachedCandidates, updateCachedCandidates] = useState<
    SyllabusCandidate[]
  >(candidates)
  const [lastUpdate, updateLastUpdate] = useState<number>(
    Number(syllabus?.lastUpdated)
  )
  const [candidatesPatchingProgress, setCandidatesPatchingProgress] = useState<{
    [key: string]: string | undefined
  }>({})
  const [changeRequest, setChangeRequest] = useState<IChangeRequest | null>(
    null
  )
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    updateCachedCandidates(candidates)
  }, [candidates])

  const patchCandidate = useMemo(
    () => (update: ICandidateUpdateExtended, cb?: (good: boolean) => void) => {
      ;(async () => {
        const { id, grade } = update
        if (candidatesPatchingProgress[id] === 'pending') return

        setCandidatesPatchingProgress((tasks) => ({
          ...tasks,
          [id]: 'pending',
        }))

        const cachedCandidate:
          | SyllabusCandidate
          | undefined = cachedCandidates.find(
          (candidate) => candidate.id === update.id
        )

        try {
          ReactGA.event({
            category: 'Grade Submission',
            action: 'Patch Candidate',
            label: syllabusId,
          })

          const { status, data } = await axios({
            method: 'patch',
            url: `${candidatesBaseUrl}/${encodeURIComponent(id)}`,
            data: {
              id,
              grade,
            },
          })

          if (status !== 200 && status !== 205) {
            throw new Error()
          }

          let updates = [data]
          if (status === 205) {
            const res = await axios.get(candidatesBaseUrl)
            if (res.status === 200) {
              updates = res.data
            }
          }

          updateCachedCandidates((cachedCandidates) =>
            cachedCandidates.map((it: any) => {
              const c = updates.find((itt: any) => itt.id === it.id)
              return !c
                ? it
                : {
                    ...it,
                    grade: c.grade,
                  }
            })
          )
          updateLastUpdate(Date.now())
          cb && cb(true)
          setCandidatesPatchingProgress((tasks) => ({
            ...tasks,
            [id]: undefined,
          }))
        } catch (err) {
          setCandidatesPatchingProgress((tasks) => ({
            ...tasks,
            [id]: `error-${cachedCandidate?.grade !== grade ? 'g' : 'r'}`,
          }))
          cb && cb(false)
        }
      })()
    },
    [
      candidatesBaseUrl,
      cachedCandidates,
      candidatesPatchingProgress,
      syllabusId,
    ]
  )

  const onPatchRequest = useMemo(
    () => (update: ICandidateUpdateExtended, cb?: (good: boolean) => void) => {
      if (viewOnly) return
      const candidate = cachedCandidates.find((c) => c.id === update.id)
      if (!candidate) return

      if (candidate.grade && candidate.grade !== update.grade) {
        handleChangeGrade(candidate, update, cb)
      } else {
        patchCandidate(update, cb)
      }
    },
    [cachedCandidates, setChangeRequest, setShowModal, patchCandidate, viewOnly]
  )

  const handleChangeGrade = (
    candidate: SyllabusCandidate,
    update: { id: string; grade?: string },
    cb?: (good: boolean) => void
  ) => {
    const changeRequest = {
      candidate,
      update,
      cb,
    }

    if (isOCR()) {
      setChangeRequest(null)
      patchCandidate(changeRequest.update, changeRequest.cb)
    } else {
      setChangeRequest(changeRequest)
      setShowModal(true)
    }
  }

  const isShowSetXGradeButton = useMemo(() => {
    const isCandidatesWithoutGradeExist = cachedCandidates.some(
      (candidate) => !candidate.grade
    )
    const isSupportedDataSource =
      syllabus?.dataSource === DataSource.CAMS ||
      syllabus?.dataSource === DataSource.OEPS

    return isCandidatesWithoutGradeExist && isOCR() && isSupportedDataSource
  }, [cachedCandidates, syllabus])

  const clearPatchingErrors = useMemo(
    () => (ids?: string[]) => {
      setCandidatesPatchingProgress((x) =>
        Object.entries(x).reduce(
          (acc, [key, val = '']) => ({
            ...acc,
            ...(val.startsWith('error') && (!ids || ids.includes(key))
              ? {}
              : { [key]: val }),
          }),
          {}
        )
      )
    },
    [setCandidatesPatchingProgress]
  )

  const candidatesWithoutGrades = useMemo(
    () => cachedCandidates.filter((candidate) => !candidate.grade).length,
    [cachedCandidates]
  )

  useEffect(() => {
    clearPatchingErrors()
  }, [clearPatchingErrors, gradeFilter])

  const bulkSetXGradeUrl = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/bulk-set-x-grade/centre/${centreId}/syllabus/${syllabusId}`,
      method: 'patch',
    }
  }, [])

  const bulkSetXGradeTask = useAsyncTaskAxios<
    AxiosResponse<InstructionMaterialDTO>
  >(axios, bulkSetXGradeUrl)

  useEffect(() => {
    if (bulkSetXGradeTask.result) {
      setIsSetXGradeModalOpen(false)
      updateCachedCandidates((cachedCandidates) => {
        return cachedCandidates.map((candidate) => {
          return candidate.grade ? candidate : { ...candidate, grade: 'X' }
        })
      })
    }
  }, [bulkSetXGradeTask.result])

  const changeGrade = () => {
    if (changeRequest) {
      patchCandidate(changeRequest.update, changeRequest?.cb)
      setShowModal(false)
    }
    setChangeRequest(null)
  }

  const title = useMemo(() => syllabus.syllabusName || syllabus.syllabusCode, [
    syllabus,
  ])
  const subTitle = useMemo(
    () =>
      getSubTitle(
        syllabus.qualification,
        syllabus.syllabusCode,
        syllabus.qualLevel,
        syllabus.dataSource
      ),
    [syllabus]
  )

  return (
    <div>
      <CandidatesList
        approvalDisabled={approvalDisabled}
        gradeFilter={gradeFilter}
        showErrors={showErrors}
        toggleShowErrors={toggleShowErrors}
        candidates={cachedCandidates}
        candidatesPatchingProgress={candidatesPatchingProgress}
        clearPatchingErrors={clearPatchingErrors}
        patchCandidate={onPatchRequest}
        lastUpdated={lastUpdate}
        upToDate={
          Object.values(candidatesPatchingProgress).filter(
            (x) => x === 'pending'
          ).length === 0
        }
        viewOnly={viewOnly}
        syllabus={syllabus}
        openSetXGradeModal={() => setIsSetXGradeModalOpen(true)}
        isShowSetXGradeButton={isShowSetXGradeButton}
      />
      {changeRequest && (
        <ChangingGradeModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          changeRequest={changeRequest}
          changeGrade={changeGrade}
        />
      )}
      <BulkSetXGradeModal
        header={title}
        subheader={subTitle}
        ancillery={`${cachedCandidates.length} candidates`}
        showSpinner={bulkSetXGradeTask.pending && bulkSetXGradeTask.started}
        isOpen={isSetXGradeModalOpen}
        onClose={() => setIsSetXGradeModalOpen(false)}
        candidatesWithoutGrade={candidatesWithoutGrades}
        bulkSetXGrade={() => bulkSetXGradeTask.start()}
      />
    </div>
  )
}
