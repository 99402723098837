import React, { useMemo } from 'react'
import { Label } from 'reactstrap'

import { CentreSyllabus, escapeSlashes } from '../../../../common'
import { formatDate } from '../../../candidates-list/constants'

export const CentreSyllabusPageUpdatedDate: React.FC<{
  centres: CentreSyllabus[]
}> = ({ centres }): JSX.Element => {
  const latestDate = useMemo(
    () =>
      centres.reduce((acc, curr) => Math.max(acc, curr.lastUpdated || 0), 0),
    [centres]
  )

  const lastUpdatedString = useMemo(
    () => escapeSlashes(formatDate(new Date(latestDate), true, true), ' / '),
    [latestDate]
  )
  return (
    <>
      {!!latestDate && (
        <Label>
          <div>Last updated on</div>
          <div>{lastUpdatedString}</div>
        </Label>
      )}
    </>
  )
}
