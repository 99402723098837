import React, { FC } from 'react'
import { Router } from 'react-router-dom'
import { Route } from 'react-router'

import { useConfig, Loading, AppState, Auth0Provider } from './common'
import { RoutesWithHeader } from './components/routes-with-header/routes-with-header'
import { Container } from 'reactstrap'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import history from './common/services/history'
import getTextFromToken from './common/services/tokenised-text'

const onRedirectCallback = (appState: AppState): void => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

const AppWithConfig: FC = ({ children }): JSX.Element => {
  const config = useConfig()
  if (!config.mainConfigLoaded) {
    return (
      <Container>
        <Loading className="mt-5 d-block mx-auto" />
      </Container>
    )
  }
  return (
    <Container fluid className="m-0 p-0 pb-3">
      <Auth0Provider
        onRedirectCallback={onRedirectCallback}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        {...config.config.auth0Config!}
      >
        {children}
      </Auth0Provider>
    </Container>
  )
}
const App: FC = (): JSX.Element => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{getTextFromToken('title')}</title>
          <link rel="canonical" href="http://mysite.com/example" />
          <link rel="icon" href={`/${getTextFromToken('favicon')}`} />
        </Helmet>
      </HelmetProvider>
      <AppWithConfig>
        <Router history={history}>
          <Route component={RoutesWithHeader} />
        </Router>
      </AppWithConfig>
    </>
  )
}

export default App
