import axios, { AxiosResponse } from 'axios'
import { useAsyncRun, useAsyncTaskAxios } from 'react-hooks-async'
import { Button, ModalProps, Spinner } from 'reactstrap'
import React, { FC, useMemo, useEffect } from 'react'
import ReactGA from 'react-ga'
import getTextFromToken from '../../common/services/tokenised-text'
import {
  CentreSyllabus,
  PreSignedUploadUrl,
  ValuationStatus,
  useDownloadFile,
  getSubTitle,
  RsModalFooter,
  RsModalHeader,
  RsModalBody,
  ProjectModal,
  InlineErrorMessageNoBorder,
  Title,
} from '../../common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowToBottom, faCheck } from '@fortawesome/pro-solid-svg-icons'

interface IDownloadModal {
  onClose: () => void
}

export const DownloadModal: FC<
  ModalProps & CentreSyllabus & IDownloadModal
> = ({
  id,
  syllabusCode,
  syllabusName,
  valuationStatus,
  qualLevel,
  dataSource,
  totalCandidates,
  centreId,
  isOpen,
  qualification,
  onClose,
}) => {
  const getCandidatesCsvUrl = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/syllabuses/${id}/candidates/csv`,
    }
  }, [centreId, id])

  const getCandidatesCsvResult = useAsyncTaskAxios<
    AxiosResponse<PreSignedUploadUrl>
  >(axios, getCandidatesCsvUrl)

  const download = useDownloadFile({
    fileUrl: `${getCandidatesCsvResult.result?.data.url}`,
    fileName: `${centreId}_${syllabusCode}.csv`,
  })

  useAsyncRun(getCandidatesCsvResult.result && download)

  const subTitle = useMemo(() => {
    return getSubTitle(qualification, syllabusCode, qualLevel, dataSource)
  }, [qualification, qualLevel, syllabusCode])

  const downloadPending = useMemo(
    () =>
      (getCandidatesCsvResult.pending && getCandidatesCsvResult.started) ||
      (getCandidatesCsvResult.result != null &&
        download.pending &&
        download.started),
    [download, getCandidatesCsvResult]
  )

  useEffect(() => {
    if (download.result && isOpen) {
      onClose()
    }
  }, [download.result])

  return (
    <ProjectModal
      centered
      isOpen={isOpen}
      toggle={!(download.pending && download.started) ? onClose : undefined}
      dataTestid="download-modal"
    >
      <RsModalHeader
        toggle={!(download.pending && download.started) ? onClose : undefined}
        className="bg-white px-5 pt-5 pb-25"
      >
        <Title
          title={syllabusName}
          subTitle={subTitle}
          ancillery={`${totalCandidates} candidates`}
        />
      </RsModalHeader>
      <RsModalBody className="px-5">
        <div className="font-weight-bold">Download Template</div>
        {[
          ValuationStatus.NOT_STARTED,
          ValuationStatus.INCOMPLETE,
          ValuationStatus.COMPLETE,
        ].includes(valuationStatus) && (
          <>
            <p>{getTextFromToken('downloadModalBody')}</p>
            <p>
              Once you have downloaded and filled in the template, you can
              return to this page to upload it.
            </p>
          </>
        )}
        {valuationStatus === ValuationStatus.SUBMITTED && (
          <>
            <p>
              This template is a CSV file that contains all the data you have
              provided. It is awaiting approval by your Head of Centre and
              cannot currently be edited.{' '}
              <u>If you require assistance please contact us.</u>
            </p>
          </>
        )}
        {valuationStatus === ValuationStatus.APPROVED && (
          <>
            <p>
              This template is a CSV file that contains all the data you have
              provided. It has been approved and submitted by your Head of
              Centre and cannot be edited.{' '}
              <u>If you require assistance please contact us.</u>
            </p>
          </>
        )}
        {download.error && (
          <InlineErrorMessageNoBorder title="Download failed, please try again and if the problem persists contact your system administrator" />
        )}
      </RsModalBody>
      <RsModalFooter className="px-5 pb-5">
        <Button
          className="ml-0"
          disabled={download.pending && download.started}
          color="primary"
          onClick={() => {
            ReactGA.event({
              category: 'Grade Submission',
              action: 'Download Template',
              label: id,
            })
            getCandidatesCsvResult.start()
          }}
          data-testid="syllabus-modal-csv-template-download-button"
        >
          {downloadPending ? (
            <Spinner className="mr-2" size="sm" />
          ) : (
            <FontAwesomeIcon
              className="mr-2"
              icon={download.result ? faCheck : faArrowToBottom}
            />
          )}
          Download
        </Button>
      </RsModalFooter>
    </ProjectModal>
  )
}
