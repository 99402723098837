import React, { useMemo } from 'react'
import { Col, Label, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/pro-solid-svg-icons'

import {
  getSubTitleBySyllabus,
  escapeSlashes,
  Title,
  CentreSyllabus,
} from '../../../../../common'
import { formatDate } from '../../../../candidates-list/constants'

interface ISubjectPageHeaderOnlyView {
  viewOnlyState: string
  syllabus: CentreSyllabus
}

export const SubjectPageHeaderReadOnly: React.FC<ISubjectPageHeaderOnlyView> = ({
  viewOnlyState,
  syllabus,
}) => {
  const lastUpdatedString = useMemo(
    () =>
      escapeSlashes(
        formatDate(new Date(syllabus?.lastUpdated || Date.now()), true, true),
        ' / '
      ),
    [syllabus]
  )

  return (
    <Row>
      <Col>
        <Title
          title={syllabus?.syllabusName || ''}
          subTitle={getSubTitleBySyllabus(syllabus)}
          ancillery={`${syllabus?.totalCandidates} candidates`}
          titleColor={viewOnlyState === 'approved' ? 'success' : 'primary'}
        />
      </Col>
      <Col className="text-right">
        <Label
          className="d-flex justify-content-end font-weight-bold font-normal"
          style={{ lineHeight: '16px' }}
        >
          <FontAwesomeIcon className="mr-2" icon={faEye} />
          This is a view only mode
        </Label>
        <Label
          className="d-flex justify-content-end opacity-59 font-weight-semi-bold"
          style={{ lineHeight: '16px' }}
        >
          Last updated on {lastUpdatedString}
        </Label>
      </Col>
    </Row>
  )
}
