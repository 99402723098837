import React from 'react'
import { Button, Col, Row, Spinner } from 'reactstrap'
import {
  ManagedUser,
  RsModalHeader,
  ProjectModal,
  RsModalBody,
  Title,
} from '../../common'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface IRemoveUserModalOptions {
  centreId: string
  deleteInProgress: boolean
  deleteError: Error | null
  isOpen: boolean
  setOpen: (val: boolean) => void
  onSubmit: () => void
}

export const RemoveUserModal: React.FC<
  ManagedUser & IRemoveUserModalOptions
> = ({
  name,
  surname,
  email,
  role,
  centreId,
  deleteInProgress,
  deleteError,
  isOpen,
  setOpen,
  onSubmit,
}) => {
  return (
    <ProjectModal
      className={`remove-user-modal ${deleteInProgress ? 'disabled' : ''}`}
      centered
      isOpen={isOpen}
      dataTestid="remove-user-modal"
    >
      <RsModalHeader
        toggle={() => !deleteInProgress && setOpen(false)}
        className="bg-white px-5 pt-5 pb-25"
      >
        <Title title={`${name} ${surname}`} subTitle={email} />
      </RsModalHeader>
      <RsModalBody className="pt-4 px-5 pb-5 rounded-bottom">
        <div>
          <p className="font-weight-bold font-larger">
            Deleting a current user
          </p>
          <p className="font-normal">
            You are about to remove this user with <b>{role}</b> role from{' '}
            <b>{centreId}</b> centre.
            <u className="d-block">Please confirm if you wish to proceed.</u>
          </p>
          {deleteError && (
            <div className="text-danger font-weigh-bold">
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-3" />
              <span>Failed to delete user, please try again</span>
            </div>
          )}
          <Row className="mt-5 ">
            <Col xs="auto">
              <Button
                color="e4"
                disabled={deleteInProgress}
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  onSubmit()
                  setOpen(false)
                }}
                disabled={deleteInProgress}
                color="danger"
              >
                {deleteInProgress ? (
                  <Spinner size="sm" className="mr" />
                ) : (
                  <span>Yes, please remove this user</span>
                )}
              </Button>
            </Col>
          </Row>
        </div>
      </RsModalBody>
    </ProjectModal>
  )
}
