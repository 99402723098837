import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons'
import { ApproveModal } from './approval-modal/approve-modal'
import { CentreSyllabus } from '../../../../common'

interface Props {
  syllabus: CentreSyllabus
  approvalDisabled: boolean
  setSyllabusCallback: (syllabus: CentreSyllabus | undefined) => void
}

export const ApprovalButton: React.FC<Props> = ({
  syllabus,
  approvalDisabled,
  setSyllabusCallback,
}: Props) => {
  const [showApprovalModal, setShowApprovalModal] = useState(false)

  return (
    <>
      <Button
        disabled={approvalDisabled}
        color="success"
        className="text-center text-white"
        onClick={(e) => {
          e.preventDefault()
          setShowApprovalModal(true)
        }}
        data-testid="syllabus-page-send-for-approval-button"
      >
        <FontAwesomeIcon icon={faPaperPlane} fixedWidth />
        <div>Send for approval</div>
      </Button>
      {syllabus && (
        <ApproveModal
          {...syllabus}
          isOpen={showApprovalModal}
          setSyllabusCallback={setSyllabusCallback}
          isOpenCallback={setShowApprovalModal}
        />
      )}
    </>
  )
}
