import React from 'react'
import { Button, Container, Nav } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/pro-regular-svg-icons'
import { faCalendarCheck } from '@fortawesome/pro-solid-svg-icons'

import getTextFromToken from '../../../../common/services/tokenised-text'
import { isCambridgeInternational, isOCR } from '../../../../common'

interface ICentreSyllabusPageNotice {
  reopenedCount: number
  showReopenedOnly: boolean
  showSyllabuses: () => void
}

export const CentreSyllabusPageNotice: React.FC<ICentreSyllabusPageNotice> = ({
  reopenedCount,
  showReopenedOnly,
  showSyllabuses,
}): JSX.Element => {
  return (
    <>
      {reopenedCount > 0 && (
        <Nav className="error-nav bg-primary text-white font-weight-bold py-3 top-border">
          <Container className="d-flex align-items-center justify-content-between">
            <span className="d-inline-flex align-items-center">
              <FontAwesomeIcon icon={faBell} className="mr-3" />
              {`You have ${reopenedCount} ${getTextFromToken(
                reopenedCount > 1 ? 'syllabuses' : 'syllabus'
              )} that ${reopenedCount > 1 ? 'are' : 'is'} open for submission`}
            </span>
            {showReopenedOnly && (
              <Container className="w-50 text-right px-0 mx-0">
                <Button
                  color="white"
                  className="text-primary"
                  onClick={showSyllabuses}
                >{`Show ${
                  reopenedCount > 1 ? 'these' : 'that'
                } ${getTextFromToken(
                  reopenedCount > 1 ? 'syllabuses' : 'syllabus'
                )}`}</Button>
              </Container>
            )}
          </Container>
        </Nav>
      )}
      <Nav className="error-nav bg-success text-white font-weight-bold py-4">
        <Container>
          <FontAwesomeIcon icon={faCalendarCheck} fixedWidth className="mr-3" />
          {isCambridgeInternational() && (
            <>
              <>
                The deadline for submitting grades <u>has now passed.</u>
              </>
              <div className="mt-2 cust-margin">
                You can no longer submit data into the system. You can only view
                and download. If you have any queries or questions please
                contact us. Thank you.
              </div>
            </>
          )}
          {isOCR() && (
            <>
              <>
                The deadline for submitting some qualifications{' '}
                <u>has passed.</u>
              </>
              <ul className="mt-3 ml-4">
                <li>
                  You can only submit data for the qualifications that are still
                  open for teacher assessed grades.
                </li>
                <li>
                  For others you can view and download. If you have any queries
                  please contact us. Thank you.
                </li>
              </ul>
            </>
          )}
        </Container>
      </Nav>
    </>
  )
}
