import React from 'react'
import { Redirect } from 'react-router-dom'
import { Container } from 'reactstrap'
import { isCAAdmin, isOCR, useAuth0 } from '../../common'
import { LandingPage } from '../landing-page/landing-page'

export const HomePage: React.FC = (): JSX.Element => {
  const { user, currentCentre } = useAuth0()

  if (user && isOCR()) {
    return <LandingPage />
  }

  if (user && isCAAdmin(user)) {
    return <Redirect to="centres" />
  }

  if (user && currentCentre) {
    return <Redirect to={`centres/${currentCentre}`} />
  }
  return <Container></Container>
}
