import React from 'react'

import { ModalFooter, ModalFooterProps } from 'reactstrap'

export const RsModalFooter: React.FC<ModalFooterProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <ModalFooter
      {...rest}
      className={`bg-white border-0 rounded-bottom text-left justify-content-start ${className}`}
    >
      {children}
    </ModalFooter>
  )
}
