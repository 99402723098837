import { CentreSyllabus } from '../types/types'
import { DataSource } from '../enum/data-source'

export const getSubTitleBySyllabus = (syllabus: CentreSyllabus): string => {
  return getSubTitle(
    syllabus.qualification,
    syllabus.syllabusCode,
    syllabus.qualLevel,
    syllabus.dataSource
  )
}

export const getSubTitle = (
  qualification: string,
  syllabusCode: string,
  qualLevel?: string,
  dataSource?: string
): string => {
  if (!!qualLevel) {
    return dataSource === DataSource.CAMS
      ? `${qualLevel} ${syllabusCode}`
      : `${qualification} - ${qualLevel} ${syllabusCode}`
  }
  return `${qualification} ${syllabusCode}`
}
