import React from 'react'

import { ModalHeader, ModalHeaderProps } from 'reactstrap'
import './modal-header.scss'

export const RsModalHeader: React.FC<ModalHeaderProps> = ({
  children,
  className,
  toggle,
  ...rest
}) => {
  return (
    <ModalHeader
      tag="div"
      className={`${className}  modal-header-cj justify-content-left font-weight-bold border-0`}
      toggle={toggle}
      {...rest}
    >
      {children}
    </ModalHeader>
  )
}
