import React from 'react'
import { Col, Row } from 'reactstrap'

import { ISyllabusProgressProps, Title, BUSINESS_UNITS } from '../../../common'
import { CandidateStats } from './candidate-stats'
import { StatusAndControlBlock } from './status-and-control-block'

export const OcrSyllabusProgress: React.FC<ISyllabusProgressProps> = ({
  title,
  subTitle,
  syllabusId,
  centreId,
  totalCandidates,
  gradedCandidates,
  inProgress,
  user,
  syllabusTemplateFileName,
  closed,
  is,
  onUpdate,
  deadline,
  extendedDeadline,
}) => {
  return (
    <Row className="align-items-center justify-content-between">
      <Col>
        <Title title={title} subTitle={subTitle} />
      </Col>
      <Col xs="auto" className="text-right pl-3">
        <CandidateStats
          syllabusId={syllabusId}
          gradedCandidates={gradedCandidates}
          totalCandidates={totalCandidates}
        />
      </Col>
      <StatusAndControlBlock
        businessUnit={BUSINESS_UNITS.OCR}
        title={title}
        subTitle={subTitle}
        syllabusId={syllabusId}
        centreId={centreId}
        totalCandidates={totalCandidates}
        gradedCandidates={gradedCandidates}
        inProgress={inProgress}
        user={user}
        syllabusTemplateFileName={syllabusTemplateFileName}
        closed={closed}
        is={is}
        onUpdate={onUpdate}
        deadline={deadline}
        extendedDeadline={extendedDeadline}
      />
    </Row>
  )
}
