import React from 'react'
import { Container } from 'reactstrap'
import { AxiosResponse } from 'axios'

import { CentreSyllabus, SyllabusCandidate } from '../../../../common'
import { SubjectPageHeaderEditMode } from './subject-page-header-edit-mode/subject-page-header-edit-mode'
import { SubjectPageHeaderReadOnly } from './subject-page-header-read-only/subject-page-header-read-only'

interface ISubjectPageHeader {
  syllabus: CentreSyllabus | undefined
  viewOnly: boolean
  showButtons: boolean
  approvalDisabled: boolean
  viewOnlyState: string
  onUploadComplete: (uploadedWithErr: boolean) => void
  setSyllabusCallback: (syllabus: CentreSyllabus | undefined) => void
}

export const SubjectPageHeader: React.FC<ISubjectPageHeader> = ({
  syllabus,
  viewOnly,
  showButtons,
  approvalDisabled,
  viewOnlyState,
  onUploadComplete,
  setSyllabusCallback,
}) => {
  return (
    <>
      <Container>
        {syllabus && !viewOnly && (
          <SubjectPageHeaderEditMode
            syllabus={syllabus}
            showButtons={showButtons}
            approvalDisabled={approvalDisabled}
            viewOnly={viewOnly}
            onUploadComplete={onUploadComplete}
            setSyllabusCallback={setSyllabusCallback}
          />
        )}

        {syllabus && viewOnly && (
          <SubjectPageHeaderReadOnly
            viewOnlyState={viewOnlyState}
            syllabus={syllabus}
          />
        )}
      </Container>
    </>
  )
}
