import React from 'react'
import { Col, Label, Row } from 'reactstrap'

import { TabList } from '../../../../common/components/tabs'
import { Tab } from '../../../../common/components/tabs/subcomponents/tab'
import { TabContentLabelWithCount } from '../../../../common/components/tab-content-label-with-count/tab-content-label-with-count'
import getTextFromToken from '../../../../common/services/tokenised-text'
import { SyllabusCandidate } from '../../../../common/types'

interface ITeacherAssessedGradesProps {
  gradeFilter: string
  candidates: SyllabusCandidate[]
  gradedCandidatesCount: number
  showErrors: boolean
  grading: string[]
  gradingMap: Record<string, number>
}

export const TeacherAssessedGrades: React.FC<ITeacherAssessedGradesProps> = ({
  gradeFilter,
  candidates,
  gradedCandidatesCount,
  showErrors,
  grading,
  gradingMap,
}) => {
  return (
    <Row>
      <Col xs="auto" className="font-weight-bold text-secondary">
        <Label className="m-0">View All</Label>
        <Row className="no-gutters">
          <Tab
            className="mt-3"
            autoSize
            to={`?grade=${encodeURIComponent('ALL')}`}
          >
            <TabContentLabelWithCount
              label={getTextFromToken('assessedGrades')}
              isActive={gradeFilter === 'ALL'}
              outOfCount={candidates.length}
              count={gradedCandidatesCount}
              countPending={false}
              className="candidates-filter-main-tab"
              errorMessage={
                showErrors && candidates.filter((x) => !x.grade).length > 0
                  ? 'Not all candidates graded'
                  : undefined
              }
            />
          </Tab>
        </Row>
      </Col>
      <Col className="font-weight-bold text-secondary">
        <Label className="m-0">{getTextFromToken('filterText')}</Label>
        <TabList className="mb-45">
          {grading.map((key) => (
            <Tab
              className="mt-3"
              autoSize
              key={`grade-${key}`}
              to={`?grade=${encodeURIComponent(key)}`}
              disabled={(gradingMap[key] || 0) === 0}
            >
              <TabContentLabelWithCount
                label={key}
                isActive={gradeFilter === key}
                count={gradingMap[key] || 0}
                countPending={false}
                className="candidates-filter-tab-content"
                errorMessage={undefined}
              />
            </Tab>
          ))}
        </TabList>
      </Col>
    </Row>
  )
}
